// Webpack Imports:
import * as bootstrap from "bootstrap";

/* [GS]Header [B]Navigation */
document.addEventListener("DOMContentLoaded", function () {
	if (window.innerWidth > 992) {
		document
			.querySelectorAll(".navbar .nav-item")
			.forEach(function (everyItem) {
				everyItem.addEventListener("mouseover", function (e) {
					var el_link = this.querySelector("a[data-bs-toggle]");

					if (el_link != null) {
						var nextEl = el_link.nextElementSibling;
						el_link.classList.add("show");
						nextEl.classList.add("show");
					}
				});
				everyItem.addEventListener("mouseleave", function (e) {
					var el_link = this.querySelector("a[data-bs-toggle]");

					if (el_link != null) {
						var nextEl = el_link.nextElementSibling;
						el_link.classList.remove("show");
						nextEl.classList.remove("show");
					}
				});
			});
	}
});

/* [GS]Header [B]Navigation: Auto Hide Navigation OnScrollDown */
document.addEventListener("DOMContentLoaded", function () {
	var elAutoHide = $("#fixed-navbar");

	if (elAutoHide) {
		var lastScrollTop = 0;
		window.onscroll = () => {
			var scrollTop = window.scrollY;
			if (scrollTop < lastScrollTop) {
				elAutoHide.removeClass("scrolled-down");
				elAutoHide.addClass("scrolled-up");
				elAutoHide.addClass("active");
				$("#fixed-navbar .nav-btn")
					.removeClass("btn-sec-1")
					.addClass("btn-sec-2");
				$("#fixed-navbar .logotype").addClass("d-none");
				$("#fixed-navbar .active-logotype").removeClass("d-none");
			} else {
				elAutoHide.removeClass("scrolled-up");
				elAutoHide.addClass("scrolled-down");
				elAutoHide.removeClass("active");
				$("#fixed-navbar .nav-btn")
					.removeClass("btn-sec-2")
					.addClass("btn-sec-1");
				$("#fixed-navbar .logotype").removeClass("d-none");
				$("#fixed-navbar .active-logotype").addClass("d-none");
			}
			lastScrollTop = scrollTop;

			if (document.body.scrollTop === lastScrollTop) {
				elAutoHide.removeClass("active");
				$("#fixed-navbar .nav-btn")
					.removeClass("btn-sec-2")
					.addClass("btn-sec-1");
				$("#fixed-navbar .logotype").removeClass("d-none");
				$("#fixed-navbar .active-logotype").addClass("d-none");
			}

			lastScrollTop = scrollTop;
		};
	}
});

// Count animation 2.0 with DATA Attribute
$(".count").each(function () {
	let $this = $(this),
		countTo = $this.attr("data-count");
	$({
		countNum: $this.text(),
	}).animate(
		{
			countNum: countTo,
		},
		{
			duration: 1300,
			easing: "linear",
			step: function () {
				$this.text(commaSeparateNumber(Math.floor(this.countNum)));
			},
			complete: function () {
				$this.text(commaSeparateNumber(this.countNum));
			},
		}
	);
});

function commaSeparateNumber(val) {
	while (/(\d+)(\d{3})/.test(val.toString())) {
		val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
	}
	return val;
}

function toggleMobileNav() {
	$(".burger").toggleClass("active");
	$(".sidenav, .side-nav-wr").toggleClass("active");
}

function toggleNavLink(e) {
	$(e.currentTarget).toggleClass("active");
}

document.addEventListener("DOMContentLoaded", function () {
	$(".burger").click(() => toggleMobileNav());
	$(".menu-item-has-children").click((e) => toggleNavLink(e));
});

$(document).ready(function () {
	$("#fixed-navbar").hover(
		function () {
			$("#fixed-navbar").addClass("active");
			$("#fixed-navbar .nav-btn")
				.removeClass("btn-sec-1")
				.addClass("btn-sec-2");
			$("#fixed-navbar .logotype").addClass("d-none");
			$("#fixed-navbar .active-logotype").removeClass("d-none");
		},
		function () {
			if (window.scrollY <= 0) {
				$("#fixed-navbar").removeClass("active");
				$("#fixed-navbar .nav-btn")
					.removeClass("btn-sec-2")
					.addClass("btn-sec-1");
				$("#fixed-navbar .logotype").removeClass("d-none");
				$("#fixed-navbar .active-logotype").addClass("d-none");
			}
			if (window.scrollY > 0) {
				$("#fixed-navbar").addClass("active");
				$("#fixed-navbar .nav-btn")
					.removeClass("btn-sec-1")
					.addClass("btn-sec-2");
				$("#fixed-navbar .logotype").addClass("d-none");
				$("#fixed-navbar .active-logotype").removeClass("d-none");
			}
		}
	);

	/* [Slider] Success Stories */
	let $carousel = $(".stories-list");

	let settings = {
		pauseOnFocus: false,
		pauseOnHover: false,
		infinite: true,
		autoplay: true,
		dots: false,
		arrows: true,
		prevArrow:
			'<svg class="slick-prev" width="34" height="56" viewBox="0 0 34 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M27.5304 56L34 49.42L12.9852 28L34 6.58L27.5304 5.65593e-07L-2.44784e-06 28L27.5304 56Z" fill="#212121"/></svg>',
		nextArrow:
			'<svg class="slick-next" width="34" height="56" viewBox="0 0 34 56" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.46964 0L0 6.58L21.0148 28L0 49.42L6.46964 56L34 28L6.46964 0Z" fill="#212121"/></svg>',
		slide: ".story-item",
		slidesToShow: 1,
		centerMode: true,
		centerPadding: "60px",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					arrows: false,
				},
			},
		],
	};

	function setSlideVisibility() {
		//Find the visible slides i.e. where aria-hidden="false"
		var visibleSlides = $carousel.find('.story-item[aria-hidden="false"]');
		//Make sure all of the visible slides have an opacity of 1
		$(visibleSlides).each(function () {
			$(this).css("opacity", 1);
		});

		//Set the opacity of the first and last partial slides.
		$(visibleSlides).first().prev().css("opacity", 0);
	}

	$carousel.slick(settings);
	$carousel.slick("slickGoTo", 1);
	setSlideVisibility();

	$carousel.on("afterChange", function () {
		setSlideVisibility();
	});

	/* [Slider] Partners */
	$(".partners-slider").slick({
		pauseOnFocus: false,
		pauseOnHover: false,
		focusOnSelect: false,
		focusOnChange: false,
		swipe: false,
		swipeToSlide: false,
		touchMove: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 1000,
		infinite: true,
		lazyLoad: "ondemand",
		arrows: false,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 376,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	/* [Slider] [P]Products [S]Partners */
	$(".products-partners-slider").slick({
		pauseOnFocus: false,
		pauseOnHover: false,
		focusOnSelect: false,
		focusOnChange: false,
		swipe: false,
		swipeToSlide: false,
		touchMove: false,
		infinite: true,
		lazyLoad: "ondemand",
		autoplay: true,
		speed: 200,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 375,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	/* [Slider] Success Stories */
	$(".success-stories-slider").slick({
		pauseOnFocus: false,
		pauseOnHover: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 8000,
		arrows: true,
		cssEase: "ease-in-out",
		prevArrow:
			'<button class="slide-arrow left"><svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M43.0002 84.6664C66.012 84.6664 84.6668 66.0115 84.6668 42.9997C84.6668 19.9878 66.012 1.33301 43.0002 1.33301C19.9883 1.33301 1.3335 19.9878 1.3335 42.9997C1.3335 66.0115 19.9883 84.6664 43.0002 84.6664Z" stroke="#fff"/><path d="M49.2502 26.333L32.5835 42.9997L49.2502 59.6663" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
		nextArrow:
			'<button class="slide-arrow right"><svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.9998 1.33365C19.988 1.33365 1.33316 19.9885 1.33316 43.0003C1.33316 66.0122 19.988 84.667 42.9998 84.667C66.0117 84.667 84.6665 66.0122 84.6665 43.0003C84.6665 19.9885 66.0117 1.33365 42.9998 1.33365Z" stroke="#fff"/><path d="M36.7498 59.667L53.4165 43.0003L36.7498 26.3337" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
				},
			},
		],
	});

	/* [Slider] Testimonials */
	$(".testimonials-slider").slick({
		pauseOnFocus: false,
		pauseOnHover: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 7000,
		arrows: true,
		prevArrow:
			'<button class="slide-arrow left"><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="-0.5" y="0.5" width="55" height="55" transform="matrix(-1 0 0 1 55 0)" stroke="#DBDBDB"/><path d="M17 28L34.25 43.5885L34.25 12.4115L17 28Z" fill="white"/></svg></button>',
		nextArrow:
			'<button class="slide-arrow right"><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="55" height="55" stroke="#DBDBDB"/><path d="M39 28L21.75 43.5885L21.75 12.4115L39 28Z" fill="white"/></svg></button>',
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					arrows: false,
				},
			},
		],
	});
});
